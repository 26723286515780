<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Basic Dropdown</div>
                    <div class="card-body">

                        <div class="dropdown d-inline-block mr-2 mb-2">
                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown" type="button">
                                Button
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <a class="dropdown-item" href="#">Something else here</a>
                            </div>
                        </div>

                        <div class="dropdown d-inline-block mr-2 mb-2">
                            <a aria-expanded="false" aria-haspopup="true" class="btn btn-primary dropdown-toggle" data-toggle="dropdown"
                               href="#" role="button">
                                Button
                            </a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <a class="dropdown-item" href="#">Something else here</a>
                            </div>
                        </div>

                        <div class="dropdown d-inline-block">
                            <a aria-expanded="false" aria-haspopup="true" class="btn btn-primary dropdown-toggle dropdown-toggle-icon"
                               data-toggle="dropdown" href="#" role="button">
                                Button
                                <fa icon="chevron-down" size="xs"/>
                            </a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <a class="dropdown-item" href="#">Something else here</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Variations / Split</div>
                    <div class="card-body">
                        <div>
                            <div class="btn-group mr-2 mb-2">
                                <button class="btn btn-primary" type="button"><fa class="icon-push-right" icon="exclamation-circle"/>Primary</button>
                                <button aria-expanded="false" aria-haspopup="true"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" type="button">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button class="btn btn-secondary" type="button"><fa class="icon-push-right" icon="exclamation-circle"/>Secondary</button>
                                <button aria-expanded="false" aria-haspopup="true"
                                        class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" type="button">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button class="btn btn-danger" type="button"><fa class="icon-push-right" icon="exclamation-circle"/>Danger</button>
                                <button aria-expanded="false" aria-haspopup="true"
                                        class="btn btn-danger dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" type="button">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button class="btn btn-warning" type="button"><fa class="icon-push-right" icon="exclamation-circle"/>Warning</button>
                                <button aria-expanded="false" aria-haspopup="true"
                                        class="btn btn-warning dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" type="button">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button class="btn btn-success" type="button"><fa class="icon-push-right" icon="exclamation-circle"/>Success</button>
                                <button aria-expanded="false" aria-haspopup="true"
                                        class="btn btn-success dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" type="button">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="btn-group mr-2 mb-2">
                                <button class="btn btn-primary" type="button"><fa class="icon-push-right" icon="exclamation-circle"/>Primary</button>
                                <button aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split dropdown-toggle-icon" data-toggle="dropdown" type="button">
                                    <fa icon="chevron-down" size="xs"/>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button class="btn btn-secondary" type="button"><fa class="icon-push-right" icon="exclamation-circle"/>Secondary</button>
                                <button aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn btn-secondary dropdown-toggle dropdown-toggle-split dropdown-toggle-icon" data-toggle="dropdown" type="button">
                                    <fa icon="chevron-down" size="xs"/>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button class="btn btn-danger" type="button"><fa class="icon-push-right" icon="exclamation-circle"/>Danger</button>
                                <button aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn btn-danger dropdown-toggle dropdown-toggle-split dropdown-toggle-icon" data-toggle="dropdown" type="button">
                                    <fa icon="chevron-down" size="xs"/>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button class="btn btn-warning" type="button"><fa class="icon-push-right" icon="exclamation-circle"/>Warning</button>
                                <button aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn btn-warning dropdown-toggle dropdown-toggle-split dropdown-toggle-icon" data-toggle="dropdown" type="button">
                                    <fa icon="chevron-down" size="xs"/>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button class="btn btn-success" type="button"><fa class="icon-push-right" icon="exclamation-circle"/>Success</button>
                                <button aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn btn-success dropdown-toggle dropdown-toggle-split dropdown-toggle-icon" data-toggle="dropdown" type="button">
                                    <fa icon="chevron-down" size="xs"/>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Variations / Contextual</div>
                    <div class="card-body">
                        <div>
                            <div class="btn-group mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-primary dropdown-toggle" data-toggle="dropdown"
                                        type="button">Primary
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="bottom-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
                                        type="button">Secondary
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="bottom-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-danger dropdown-toggle" data-toggle="dropdown"
                                        type="button">Danger
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="bottom-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-warning dropdown-toggle" data-toggle="dropdown"
                                        type="button">Warning
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="bottom-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-success dropdown-toggle" data-toggle="dropdown"
                                        type="button">Success
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="bottom-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="btn-group mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-primary dropdown-toggle dropdown-toggle-icon"
                                        data-toggle="dropdown" type="button">Primary
                                    <fa icon="chevron-down" size="xs"/>
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="bottom-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-secondary dropdown-toggle dropdown-toggle-icon"
                                        data-toggle="dropdown" type="button">Secondary
                                    <fa icon="chevron-down" size="xs"/>
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="bottom-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-danger dropdown-toggle dropdown-toggle-icon"
                                        data-toggle="dropdown" type="button">Danger
                                    <fa icon="chevron-down" size="xs"/>
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="bottom-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-warning dropdown-toggle dropdown-toggle-icon"
                                        data-toggle="dropdown" type="button">Warning
                                    <fa icon="chevron-down" size="xs"/>
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="bottom-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-success dropdown-toggle dropdown-toggle-icon"
                                        data-toggle="dropdown" type="button">Success
                                    <fa icon="chevron-down" size="xs"/>
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="bottom-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Directions / Drop-up</div>
                    <div class="card-body">
                        <div>
                            <div class="btn-group dropup mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-primary btn-sm dropdown-toggle"
                                        data-toggle="dropdown" type="button"><fa class="icon-push-right ml-0" icon="exclamation-circle"/>Dropup
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="top-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group dropup mr-2 mb-2">
                                <button class="btn btn-primary btn-sm" type="button"><fa class="icon-push-right ml-0" icon="exclamation-circle"/>Split</button>
                                <button aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" type="button">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="top-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group dropup mr-2 mb-2">
                                <button aria-expanded="true"
                                        class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-icon" data-toggle="dropdown" type="button">
                                    With Icon
                                    <fa icon="chevron-up" size="xs"/>
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="top-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group dropup mr-2 mb-2">
                                <button class="btn btn-primary btn-sm" type="button">Split Icon</button>
                                <button aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split dropdown-toggle-icon" data-toggle="dropdown" type="button">
                                    <fa icon="chevron-up" size="xs"/>
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="top-start">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Directions / Drop-left</div>
                    <div class="card-body">
                        <div class="">
                            <div class="btn-group dropleft mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-primary btn-sm dropdown-toggle"
                                        data-toggle="dropdown" type="button"><fa class="icon-push-right ml-0" icon="exclamation-circle"/>Dropleft
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="left-top">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <div class="btn-group dropleft">
                                    <button aria-expanded="false"
                                            aria-haspopup="true"
                                            class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" type="button">
                                        <span class="sr-only">Toggle Dropdown</span>
                                    </button>
                                    <div class="dropdown-menu" role="menu" x-placement="left-top">
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="#">Separated link</a>
                                    </div>
                                </div>
                                <button class="btn btn-primary btn-sm" type="button"><fa class="icon-push-right ml-0" icon="exclamation-circle"/>Split</button>
                            </div>
                            <div class="btn-group dropleft mr-2 mb-2">
                                <button aria-expanded="true"
                                        class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-icon" data-toggle="dropdown" type="button">
                                    <fa icon="chevron-left" size="xs"/>
                                    With Icon
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="left-top">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group mr-2 mb-2">
                                <div class="btn-group dropleft">
                                    <button aria-expanded="false"
                                            aria-haspopup="true"
                                            class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split dropdown-toggle-icon" data-toggle="dropdown" type="button">
                                        <fa icon="chevron-left" size="xs"/>
                                    </button>
                                    <div class="dropdown-menu" role="menu" x-placement="left-top">
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="#">Separated link</a>
                                    </div>
                                </div>
                                <button class="btn btn-primary btn-sm" type="button">Split Icon</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Directions / Drop-right</div>
                    <div class="card-body">
                        <div class="">
                            <div class="btn-group dropright mr-2 mb-2">
                                <button aria-expanded="true" class="btn btn-primary btn-sm dropdown-toggle"
                                        data-toggle="dropdown" type="button"><fa class="icon-push-right ml-0" icon="exclamation-circle"/>Dropright
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="left-top">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group dropright mr-2 mb-2">
                                <button class="btn btn-primary btn-sm" type="button"><fa class="icon-push-right ml-0" icon="exclamation-circle"/>Split</button>
                                <button aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" type="button">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="left-top">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group dropright mr-2 mb-2">
                                <button aria-expanded="true"
                                        class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-icon" data-toggle="dropdown" type="button">
                                    With Icon
                                    <fa icon="chevron-right" size="xs"/>
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="left-top">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                            <div class="btn-group dropright mr-2 mb-2">
                                <button class="btn btn-primary btn-sm" type="button">Split Icon</button>
                                <button aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split dropdown-toggle-icon" data-toggle="dropdown" type="button">
                                    <fa icon="chevron-right" size="xs"/>
                                </button>
                                <div class="dropdown-menu" role="menu" x-placement="left-top">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
}
</script>
